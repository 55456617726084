import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const DashboardPage = () => {
  return (
    <main id="main" className="main">
      <Container fluid>
        <h1 className="mt-4">Dashboard</h1>
        <Row>
          <Col md={6} lg={3}>
            <Card className="mb-4">
              <Card.Body>
                <h2>Statistics</h2>
                {/* Add your statistics here */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default DashboardPage;
