import React from "react";
import { FaTelegram, FaYoutube, FaFacebook, FaWhatsapp } from "react-icons/fa";

const BrandsLogo = () => {
  return (
    <>
      <div className="container mb-12 mt-12 sm:mt-0 bg-blue-500">
        <h2 className="text-center">
          Join Our Market Networking Platforms and achieve greatness
        </h2>
        <div className="py-6 md:px-32 flex flex-wrap items-center justify-evenly gap-3">
          <a
            href="https://t.me/ecomspot"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram className="text-white text-6xl hover:scale-110 transition-colors duration-300" />
          </a>
          <a
            href="https://youtube.com/@ecomspot?si=vRzchT6vTzeg_u-q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="text-white text-6xl hover:scale-110 transition-colors duration-300" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-white text-6xl hover:scale-110 transition-colors duration-300" />
          </a>
          <a
            href="https://whatsapp.com/channel/0029VacBMA97z4kd9jkNdK2S"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="text-white text-6xl hover:scale-110 transition-colors duration-300" />
          </a>
        </div>
        <h3 className="text-center">
          Bringing your whole market Networking demands within your fingertips
        </h3>
      </div>
    </>
  );
};

export default BrandsLogo;
