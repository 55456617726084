import { Link } from "react-router-dom";

const Missing = () => {
  return (
    <article
      style={{
        padding: "100px",
        backgroundColor: "white",
        fontSize: "24px",
        minHeight: "100vh",
      }}
    >
      <h1>404: Page Not Found</h1>
      <p>The page you requested could not be found.</p>
      <div className="flexGrow">
        <Link to={-3}>Go Back</Link>
      </div>
    </article>
  );
};

export default Missing;
