import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import {
  ServerApi,
  retrieveDataFromLocalStorage,
  role,
} from "../../utils/Constants";
import { FaTimes, FaEdit } from "react-icons/fa";
import Notify from "../../utils/Notify";
import "./StylesFile.css";

function ResetPassword({ showModalReset, setShowModalReset, selectedUser }) {
  const localData = retrieveDataFromLocalStorage();
  const [message, setMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const showMessage = (messageContent, backgroundColor) => {
    setMessage({ content: messageContent, bgColor: backgroundColor });
  };

  const clearMessage = () => {
    setMessage(null);
  };
  const resetPassword = async () => {
    const newPass = Math.random().toString(36).substring(2, 7);

    try {
      const response = await fetch(`${ServerApi}/admin/reset/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localData.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: selectedUser.phone,
          password: newPass,
        }),
      });

      const data = await response.json();
      if (!data.success) {
        setNewPassword("Internal Server Error.");
      } else {
        setNewPassword(newPass);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {/* <Notify /> */}
      <Modal
        show={showModalReset}
        onHide={() => {
          setNewPassword("");
          setShowModalReset(false);
        }}
      >
        {newPassword && (
          <div
            style={{
              fontSize: "28px",
              textAlign: "center",
              marginTop: "30px",
              // Add more styles if needed
            }}
          >
            {newPassword}
          </div>
        )}
        <Modal.Header closeButton>
          <FaEdit
            style={{
              color: "rgb(49, 215, 245)",
              fontSize: "32px",
              marginRight: "10px",
            }}
          />
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={selectedUser.phone}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedUser.name}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="lastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={selectedUser.lastname}
                className="customInput"
              />
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={selectedUser.role}
                className="customInput"
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={() => {
              setNewPassword("");
              setShowModalReset(false);
            }}
            className="customCancelButton"
          >
            Cancel
          </Button> */}
          <Button
            variant="success"
            onClick={resetPassword}
            className="customSaveButton"
          >
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ResetPassword;
