import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { routeTo, retrieveDataFromLocalStorage } from "./utils/Constants";

import EspotApp from "./modules/espot/App";
import Login from "./modules/profile/Login";
import Profile from "./modules/temp_profile/Profile";

import AgentRoutes from "./routes/AgentRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import TraderRoutes from "./routes/TraderRoutes";
import RequireAuth from "./modules/RequireAuth";

import Unauthorized from "./modules/Unauthorized";
import NonPage from "./modules/NonPage";
import PostsPage from "./modules/espot/pages/PostsPage";
import Homepage from "./modules/espot/pages/HomePage";

function App() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const localData = retrieveDataFromLocalStorage();
  //   if (localData) {
  //     const role = localData.role;
  //     navigate(routeTo[role]);
  //   } else {
  //     navigate("/");
  //   }
  // }, []);
  return (
    // <Router>
    <div>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        <Route exact path="/" Component={Homepage} />
        <Route path="/posts" Component={PostsPage} />
        <Route path="/login" Component={Login} />
        <Route path="/profile" Component={Profile} />
        <Route element={<RequireAuth allowedRoles={["agent", "admin"]} />}>
          <Route path="/agent/*" element={<AgentRoutes />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["admin"]} />}>
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["trader", "admin"]} />}>
          <Route path="/trader/*" element={<TraderRoutes />} />
        </Route>
        <Route path="/unauthorized" Component={Unauthorized} />
        <Route path="*" Component={NonPage} />
        {/* </Route> */}
      </Routes>
    </div>
    // </Router>
  );
}

export default App;
