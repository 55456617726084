import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// from main
import "../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Component import
import Navbar from "../components/Navbar/Navbar.jsx";
import Hero from "../components/Hero/Hero.jsx";
import BrandsLogo from "../components/BrandsLogo/BrandsLogo.jsx";
import Mission from "../components/Mission/Mission.jsx";
import SpaceHolder from "../../espot/components/SpaceHolder.jsx";
import Vision from "../components/Vision/Vission.jsx";
import Services from "../components/Services/Services.jsx";
import Testimonial from "../components/Testimonial/Testimonial.jsx";
import BlogsComp from "../components/Blogs/BlogsComp.jsx";
import Footer from "../components/Footer/Footer.jsx";

const Homepage = () => {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="bg-white dark:bg-black dark:text-white text-black overflow-x-hidden">
      <Navbar />
      <Hero />
      <BrandsLogo />
      <Mission />
      <SpaceHolder />
      <Vision />
      <Services />
      <Testimonial />
      <BlogsComp />
      <Footer />
    </div>
  );
};

export default Homepage;
