import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <article
      style={{
        padding: "100px",
        backgroundColor: "white",
        fontSize: "24px",
        minHeight: "100vh",
      }}
    >
      <h1>Oops!</h1>
      <p>You are not Authorized.</p>
      <div className="flexGrow">
        <Link to={-3}>Go Back</Link>
      </div>
    </article>
  );
};

export default Unauthorized;
