import React from "react";
import { Route, Routes } from "react-router-dom";

import Comment from "../modules/superAdmin/Comment";
import LinkPage from "../modules/profile/LinkPage";
import Sidebar from "../modules/superAdmin/Sidebar";
import CreateAccount from "../modules/superAdmin/CreateAccount";
import MainPage from "../dashboard/MainPage";
import NonPage from "../modules/NonPage";
import { traderSidebarList } from "../dashboard/data/SidebarItem";
import { traderNavList } from "../dashboard/data/NavItem";

const TraderRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <MainPage SidebarList={traderSidebarList} NavList={traderNavList} />
        }
      />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/comment" element={<Comment />} />
      <Route path="/createAccount" element={<CreateAccount />} />
      <Route path="/new" element={<LinkPage />} />
      <Route path="*" element={<NonPage />} />
    </Routes>
  );
};

export default TraderRoutes;
