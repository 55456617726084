import { Link } from "react-router-dom";
import "./LinkPage.css";

const LinkPage = () => {
  return (
    <div className="linkPage">
      <Link to="/gate" className="button-link">
        <button className="button">Screening Gate</button>
      </Link>
      <Link to="/ticker" className="button-link">
        <button className="button">Cafe Ticker</button>
      </Link>
      <Link to="/cafe" className="button-link">
        <button className="button">Cafe Manager</button>
      </Link>
      <Link to="/library" className="button-link">
        <button className="button">Librarian</button>
      </Link>
      <Link to="/staff" className="button-link">
        <button className="button">Staff Manager</button>
      </Link>
      <Link to="/proctor" className="button-link">
        <button className="button">Proctor</button>
      </Link>
      <Link to="/admin" className="button-link">
        <button className="button">Admin Page</button>
      </Link>
    </div>
  );
};

export default LinkPage;
