import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Layout from "../modules/Layout";

import EditUserAccount from "../modules/superAdmin/EditUserAccount";
import ViewAllItems from "../modules/superAdmin/AllItems";
import ResetPassword from "../modules/superAdmin/ResetModal";

import UserAccounts from "../modules/superAdmin/UserAccounts";
import DashboardPage from "../modules/superAdmin/Dashboard";
import AddNewAccount from "../modules/superAdmin/RegisterAccount";

import { adminSidebarList } from "../dashboard/data/SidebarItem";
import { adminNavList } from "../dashboard/data/NavItem";
import NonPage from "../modules/NonPage";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout SidebarList={adminSidebarList} NavList={adminNavList} />
        }
      >
        <Route path="/" element={<UserAccounts />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        {/* <Route path="/editUser" element={<EditUserAccount />} /> */}
        <Route path="/users" element={<UserAccounts />} />
        <Route path="/items" element={<ViewAllItems />} />
        {/* <Route path="/reset" element={<ResetPassword />} /> */}

        <Route path="*" element={<NonPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
