import React from "react";
import "./Main.css";
import PageTitle from "./PageTitle";
import NewItem from "../../modules/agent/NewItem";

function Main() {
  return (
    <main id="main" className="main">
      <PageTitle page="Crypto wiseman" />
      {/* <NewItem /> */}
    </main>
  );
}

export default Main;
