import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";

function AlertDismissible({ setNotice, notice, status }) {
  return (
    <Alert
      show={notice}
      variant={status}
      style={{
        maxWidth: "30rem",
        marginLeft: "10px",
        maxHeight: "30rem",
        position: "fixed",
        left: "70%",
        top: "83%",
        zIndex: 10,
      }}
    >
      <div className="d-flex justify-content-end">
        <CloseButton
          onClick={() => {
            setNotice(false);
          }}
          variant="outline-success"
        ></CloseButton>
      </div>
      {/* <Alert.Heading>Message Dispaly</Alert.Heading> */}
      <p>{notice}</p>
      <hr />
    </Alert>
  );
}

export default AlertDismissible;
