import React from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
  FaTelegram,
} from "react-icons/fa";

const FooterLinks = [
  {
    title: "About",
    link: "/#about",
  },
  {
    title: "Features",
    link: "/#features",
  },
  {
    title: "Works",
    link: "/#works",
  },
  {
    title: "Career",
    link: "/#career",
  },
];
const HelpLinks = [
  {
    title: "Customer Support",
    link: "/#support",
  },
  {
    title: "Delivery Details",
    link: "/#delivery-details",
  },
  {
    title: "Terms & Conditions",
    link: "/#terms",
  },
  {
    title: "Privacy Policy",
    link: "/#policy",
  },
];
const ResourcesLinks = [
  {
    title: "Free Ebooks",
    link: "/#ebooks",
  },
  {
    title: "How To Blog",
    link: "/#blogs",
  },
  {
    title: "Subscribe",
    link: "#",
  },
];
const Footer = () => {
  return (
    <div className="text-white" style={{ backgroundColor: "#001f3f" }}>
      <section className="container py-10">
        <div className=" grid md:grid-cols-3 py-5">
          {/* company Details */}
          <div className=" py-8 px-4 ">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              Ecomspot Market Networking
            </h1>
            <p className="text-sm">
              the premier online hub where businesses connect, collaborate, and
              thrive in the dynamic global marketplace of e-commerce.
            </p>
            <br />
            {/* Social Handle */}
            <div className="flex items-center gap-4 mt-6">
              <a href="https://t.me/ecomspot" target="_blank">
                <FaTelegram className="text-4xl text-blue-500 hover:text-blue-700 transform transition duration-300 hover:scale-110" />
              </a>
              <a href="#" target="_blank">
                <FaFacebook className="text-4xl text-blue-500 hover:text-blue-700 transform transition duration-300 hover:scale-110" />
              </a>
              <a
                href="https://youtube.com/@ecomspot?si=vRzchT6vTzeg_u-q"
                target="_blank"
              >
                <FaYoutube className="text-4xl text-blue-500 hover:text-blue-700 transform transition duration-300 hover:scale-110" />
              </a>
              <a href="#" target="_blank">
                <FaLinkedin className="text-4xl text-blue-500 hover:text-blue-700 transform transition duration-300 hover:scale-110" />
              </a>{" "}
              <a
                href="https://whatsapp.com/channel/0029VacBMA97z4kd9jkNdK2S"
                target="_blank"
              >
                <FaWhatsapp className="text-4xl text-blue-500 hover:text-blue-700 transform transition duration-300 hover:scale-110" />
              </a>
            </div>
          </div>
          {/* Links */}
          <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10 ">
            <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Company
                </h1>
                <ul className={`flex flex-col gap-3`}>
                  {FooterLinks.map((link) => (
                    <li
                      key={link.title}
                      className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-400 "
                    >
                      <span>{link.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Help
                </h1>
                <ul className="flex flex-col gap-3">
                  {HelpLinks.map((link) => (
                    <li
                      key={link.title}
                      className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-400 "
                    >
                      <span>{link.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Resources
                </h1>
                <ul className="flex flex-col gap-3">
                  {ResourcesLinks.map((link) => (
                    <li
                      key={link.title}
                      className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-400 "
                    >
                      <span>{link.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <span className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-400 ">
          <span>2024</span>
        </span>
      </section>
    </div>
  );
};

export default Footer;
