import React, { useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import {
  ServerApi,
  retrieveDataFromLocalStorage,
  role,
} from "../../utils/Constants";
import { FaTimes, FaEdit } from "react-icons/fa";
import Notify from "../../utils/Notify";
import "./StylesFile.css";

function EditAccount({
  showModal,
  setShowModal,
  selectedUser,
  setSelectedUser,
}) {
  const localData = retrieveDataFromLocalStorage();
  const [message, setMessage] = useState("");
  const [notice, setNotice] = useState("");

  const showMessage = (messageContent, backgroundColor) => {
    setMessage({ content: messageContent, bgColor: backgroundColor });
  };

  const clearMessage = () => {
    setMessage(null);
  };
  function handleSaveChanges() {
    fetch(`${ServerApi}/admin/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localData.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedUser),
    }).then((result) => {
      result.json().then((data) => {
        if (data.success) {
          setShowModal(false);
          showMessage(" Account Updated successfully.", "#8bc08b");
          setNotice("Account Updated successfully");
        } else {
          setShowModal(false);
          showMessage("something went wrong!", "#f3b4a9");
          setNotice("omething went wrong!");
        }
      });
    });
  }
  return (
    <div>
      <Notify notice={notice} setNotice={setNotice} status={"info"} />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {/* {" "}
        {message && (
          <div
            className="mBar"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              position: "relative",
              backgroundColor: message.bgColor,
            }}
          >
            <p style={{ display: "flex" }}>{message.content}</p>

            <span style={{}} className="close" onClick={clearMessage}>
              <FaTimes />
            </span>
          </div>
        )}{" "} */}
        <Modal.Header closeButton>
          <FaEdit
            style={{
              color: "rgb(49, 215, 245)",
              fontSize: "24px",
              marginRight: "10px",
            }}
          />
          <Modal.Title>Edit User Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={selectedUser.phone}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedUser.name}
                onChange={(e) => {
                  setSelectedUser((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="lastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={selectedUser.lastname}
                onChange={(e) => {
                  setSelectedUser((prev) => {
                    return { ...prev, lastname: e.target.value };
                  });
                }}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={selectedUser.email}
                onChange={(e) => {
                  setSelectedUser((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                }}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={selectedUser.role}
                onChange={(e) => {
                  setSelectedUser((prev) => {
                    return { ...prev, role: e.target.value };
                  });
                }}
                className="customInput"
              >
                {role.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            className="customSaveButton"
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditAccount;
