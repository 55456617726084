import React from "react";
// import Post from "../../posts/components/post/Post";
import Posts from "../../posts/components/posts/Posts";
// import Sidebar from "../../posts/components/sidebar/Sidebar";
import Navbar from "../../espot/components/Navbar/Navbar";
import Footer from "../../espot/components/Footer/Footer";

function PostsPage() {
  return (
    <div className="bg-white dark:bg-black dark:text-white text-black overflow-x-hidden">
      <Navbar />
      <div className="home" style={{ display: "flex" }}>
        <Posts />
        {/* <Sidebar /> */}
      </div>
      {/* <Posts />
      <Sidebar />*/}
      <Footer />
    </div>
  );
}

export default PostsPage;
