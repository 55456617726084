import React, { useState, useEffect } from "react";
import {
  ServerApi,
  routeTo,
  retrieveDataFromLocalStorage,
} from "../../utils/Constants";
import "./Login.css";
import logo from "../../images/logo.jpeg";
// import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FaTimes, FaUser, FaLock, FaArrowRight } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const localData = retrieveDataFromLocalStorage();
  //   if (localData) {
  //     const role = localData.role;
  //     navigate(routeTo[role]);
  //   } else {
  //     navigate("/");
  //   }
  // }, []);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState(null);
  // const navigate = useNavigate();
  // const { setAuth } = useAuth();

  const showMessage = (messageContent, backgroundColor) => {
    setMessage({ content: messageContent, bgColor: backgroundColor });
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${ServerApi}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      });

      const data = await response.json();
      console.log(data);
      if (data.status === "failure") {
        showMessage(data.message, "#f3b4a9");
      } else {
        const role = data.role;
        const user = username;
        const token = data.token;

        // setAuth({ user, role, accessToken });
        const AuthData = JSON.stringify({
          role: `${role}`,
          user: `${user}`,
          token: `${token}`,
        });
        localStorage.setItem("AuthData", AuthData);

        setUsername("");
        setPassword("");
        navigate(routeTo[role], { replace: true });
      }
    } catch (error) {
      showMessage("Cannot reach the server!", "#f3b4a9");
    }
  };

  return (
    <>
      <nav style={{ marginTop: "10px" }} class="navbar navbar-expand ">
        <div class=" d-flex justify-content-between w-100">
          <a href="/">
            <div
              class="navbar-brand ms-sm-4 m-1 d-flex justify-content-sm-center"
              href="#"
            >
              <div>
                <img
                  style={{ height: "80px", width: "auto" }}
                  src={logo}
                  className="img-fluid"
                />
              </div>
              <div className="row ms-0 ">
                <label className="col-8 text-wrap">
                  {" "}
                  <strong>ecomspot </strong> market networking
                </label>
              </div>
            </div>
          </a>

          <div>
            <div
              class="offcanvas offcanvas-end"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div class="offcanvas-body">
                <ul className="navbar-nav me-3">
                  <li className="nav-item ">
                    <a
                      className="nav-link p-1"
                      href="#"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Help
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className=" d-flex  w-100 justify-content-center  flex-row h-75 mt-sm-5">
        <form
          className="px-3 h-75 col-md-8 col-lg-6 col-xl-4  shadow-lg m-4 rounded-4 needs-validation mt-2 mt-sm-5 text-center"
          style={{ backgroundColor: "white" }}
          onSubmit={handleLogin}
          noValidate
        >
          {message && (
            <div
              className="mBar"
              style={{
                backgroundColor: message.bgColor,
                height: "40px",
              }}
            >
              <p>{message.content}</p>
              <span
                style={{ cursor: "pointer", color: "#fa0505" }}
                onClick={clearMessage}
              >
                <FaTimes />
              </span>
            </div>
          )}

          <label className="text-center m-2 fs-2 mt-5">
            Sign In to <strong>ecomspot system</strong>{" "}
          </label>
          <div className="d-flex flex-column w-100 my-3">
            <div className="align-self-center w-75">
              <div className=" mt-2 " style={{ position: "relative" }}>
                <FaUser
                  style={{ position: "absolute", left: "2%", top: "25%" }}
                  size={20}
                />
                <input
                  type="text"
                  name="id"
                  className="form-control px-5 py-2"
                  id="id"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  value={username}
                  placeholder="Username"
                  maxLength={10}
                  required
                  style={{ backgroundColor: "#d7f3f5" }}
                />
              </div>
              <div className="mt-2" style={{ position: "relative" }}>
                <FaLock
                  style={{ position: "absolute", left: "2%", top: "25%" }}
                  size={20}
                />
                <input
                  type="password"
                  name="id"
                  className="form-control py-2 px-5"
                  id="id"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  value={password}
                  placeholder="Password"
                  required
                  style={{ backgroundColor: "#d7f3f5" }}
                />
              </div>

              <button
                type="submit"
                className="btn  p-2 px-3 w-100 d-flex flex-row justify-content-between mt-5"
                style={{ backgroundColor: "#099214", color: "white" }}
              >
                <strong>Sign In</strong>
                <FaArrowRight
                  size={15}
                  className="align-self-center"
                  color="white"
                />
              </button>
              <div className="row d-flex flex-column mt-1">
                <label className="mt-1">
                  <a href="/forgot">forgot password</a>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
