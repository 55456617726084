export const adminSidebarList = [
  {
    target: "components-nav",
    name: "Users",
    icon: "bi bi-people",
    // icon: "bi bi-menu-button-wlinke",
    to: "#",
    subItems: [
      // { link: "/admin/createAccount", name: "createAccount" },
      { link: "/admin/users", name: "user accounts" },
      // { link: "/admin/reset", name: "Reset Password" },
    ],
  },
  {
    target: "icons-nav",
    name: "Market Items",
    icon: "bi bi-gem",
    to: "#",
    subItems: [
      { link: "#", name: "Livestock" },
      { link: "#", name: "Cereal" },
      { link: "#", name: "Vegetables" },
      { link: "#", name: "Minerals" },
      { link: "#", name: "Products" },
      { link: "#", name: "Others" },
    ],
  },
  {
    target: "forms-nav",
    name: "Forms",
    icon: "bi bi-journal-text",
    to: "#",
    subItems: [
      { link: "#", name: "Application Form" },
      { link: "#", name: "Registration Form" },
      { link: "#", name: "Form Handle" },
    ],
  },
  {
    target: "tables-nav",
    name: "Market Centers",
    icon: "bi bi-layout-text-window-reverse",
    to: "#",
    subItems: [
      { link: "#", name: "Sheger" },
      { link: "#", name: "Adama" },
      { link: "#", name: "adigrat" },
      { link: "#", name: "Sheger" },
      { link: "#", name: "Adama" },
      { link: "#", name: "Mekelle" },
      { link: "#", name: "bahirdar" },
      { link: "#", name: "ambo" },
      { link: "#", name: "Gonder" },
    ],
  },
  {
    target: "charts-nav",
    name: "Charts",
    icon: "bi bi-bar-chart",
    to: "#",
    subItems: [
      { link: "#", name: "General Trending Chart" },
      { link: "#", name: "Data Charts Handler" },
      { link: "#", name: "Options" },
    ],
  },
];

export const agentSidebarList = [
  {
    target: "components-nav",
    name: "Livestoke",
    icon: "bi bi-gem",
    // icon: "bi bi-menu-button-wlinke",
    to: "#",
    subItems: [
      { link: "#", name: "goats" },
      { link: "#", name: "cattle" },
      { link: "#", name: "chicken" },
    ],
  },
  {
    target: "icons-nav",
    name: "Cereals",
    icon: "bi bi-gem",
    to: "#",
    subItems: [
      { link: "#", name: "Teff" },
      { link: "#", name: "Maize" },
      { link: "#", name: "wheat" },
      { link: "#", name: "Teff" },
      { link: "#", name: "Maize" },
      { link: "#", name: "wheat" },
    ],
  },
  {
    target: "forms-nav",
    name: "Minerals",
    icon: "bi bi-journal-text",
    to: "#",
    subItems: [
      { link: "#", name: "gold" },
      { link: "#", name: "silver" },
      { link: "#", name: "xxxx" },
      { link: "#", name: "gold" },
      { link: "#", name: "silver" },
      { link: "#", name: "xxxx" },
    ],
  },
  {
    target: "tables-nav",
    name: "Vegetables",
    icon: "bi bi-layout-text-window-reverse",
    to: "#",
    subItems: [
      { link: "#", name: "onion" },
      { link: "#", name: "garlic" },
      { link: "#", name: "potato" },
      { link: "#", name: "garlic" },
      { link: "#", name: "potato" },
    ],
  },
  // {
  //   target: "charts-nav",
  //   name: "Charts",
  //   icon: "bi bi-bar-chart",
  //   to: "#",
  //   subItems: [
  //     { link: "#", name: "General Trending Chart" },
  //     { link: "#", name: "Data Charts Handler" },
  //     { link: "#", name: "Options" },
  //   ],
  // },
];

export const traderSidebarList = [
  {
    target: "components-nav",
    name: "Documents",
    icon: "bi bi-gem",
    // icon: "bi bi-menu-button-wlinke",
    to: "#",
    subItems: [
      { link: "#", name: "Customers" },
      { link: "#", name: "Suppliers" },
      { link: "#", name: "Logistics" },
    ],
  },
  {
    target: "icons-nav",
    name: "Icons",
    icon: "bi bi-gem",
    to: "#",
    subItems: [
      { link: "#", name: "React Icons" },
      { link: "#", name: "Bootstrap Icons" },
      { link: "#", name: "Font Awesome Icons" },
    ],
  },
  {
    target: "forms-nav",
    name: "Forms",
    icon: "bi bi-journal-text",
    to: "#",
    subItems: [
      { link: "#", name: "Application Form" },
      { link: "#", name: "Registration Form" },
      { link: "#", name: "Form Handle" },
    ],
  },
  {
    target: "tables-nav",
    name: "Tables",
    icon: "bi bi-layout-text-window-reverse",
    to: "#",
    subItems: [
      { link: "#", name: "General Tables" },
      { link: "#", name: "Form Tables" },
      { link: "#", name: "Other Form Tables" },
    ],
  },
  {
    target: "charts-nav",
    name: "Charts",
    icon: "bi bi-bar-chart",
    to: "#",
    subItems: [
      { link: "#", name: "General Trending Chart" },
      { link: "#", name: "Data Charts Handler" },
      { link: "#", name: "Options" },
    ],
  },
];
