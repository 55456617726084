export const Temp = 18;
// export const ServerApi = "http://localhost:3210";
export const ServerApi = process.env.REACT_APP_API_URL;

export const role = ["custom", "admin", "trader", "agent"];
export const address = [
  "custom",
  "addiss ababa",
  "bahrdar",
  "shire",
  "mekelle",
  "shire",
  "awasa",
  "gonder",
  "ambo",
  "semera",
  "debrebrhan",
  "adigrat",
  "mojo",
];

export const routeTo = {
  admin: "/admin",
  agent: "/agent",
  trader: "/trader",
};

export function retrieveDataFromLocalStorage() {
  const jsonData = localStorage.getItem("AuthData");
  if (jsonData) {
    const myObject = JSON.parse(jsonData);
    return myObject;
  }
}

export const handleLogout = async () => {
  try {
    localStorage.removeItem("AuthData");
    return true;
  } catch (error) {
    console.log("Error: ", error);
    return false;
  }
};

export function formatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}
