import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { handleLogout } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import "./Nav.css";

function Nav() {
  const navigate = useNavigate();
  function Logout() {
    if (handleLogout()) {
      navigate("/");
    }
  }
  return (
    <div className="nav-container">
      <div className="d-flex align-items-center justify-content-end">
        <button onClick={Logout} className="btn btn-link">
          Logout <FaSignOutAlt className="ml-1" />
        </button>
      </div>
    </div>
  );
}

export default Nav;
