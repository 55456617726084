import React from "react";

function Comment() {
  return (
    <div>
      <h1 style={{ backgroundColor: "white" }}>comment section </h1>
    </div>
  );
}

export default Comment;
