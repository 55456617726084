import { React, useState } from "react";
import "./CreateAccount.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function CreateAccount({ backHome }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    email: "",
    name: "",
    lastname: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`http://localhost:3200/admin/createAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "unauthorized") {
          navigate(`/${data.message}`, { replace: true });
        }
        if (data.message === "success") {
          document.getElementById("m-container").innerText =
            "Data saved successfully";
        } else
          document.getElementById("m-container").innerText =
            "Error saving the data";
        console.log(data);

        setFormData({
          phone: "",
          password: "",
          email: "",
          name: "",
          lastname: "",
          role: "",
        });
        document.getElementById("file").value = "";
      })
      .catch((error) => {
        console.error("Error:", error);
        document.getElementById("m-container").innerText = error;
      });
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   if (file.size > 4 * 1024 * 1024) {
  //     alert("file size exceeds 4MB! ");
  //     return;
  //   }
  //   const validTypes = ["image/png", "image/jpeg", "image/jpg"];
  //   if (!validTypes.includes(file.type)) {
  //     alert("Invalid file type! Please select an image file (png, jpg, jpeg).");
  //     return;
  //   }
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     const bufferUrl = reader.result;

  //     setFormData({
  //       ...formData,
  //       image: bufferUrl,
  //     });
  //     setSelectedFile(bufferUrl);
  //     alert("file accepted");
  //   };

  //   reader.onerror = (error) => {
  //     console.log("Error: ", error);
  //   };
  // };

  function handleDownload() {
    const link = document.createElement("a");
    link.href = imgSrc;
    link.download = selectedFile.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="dropdown">
      <div className="buttonContainer">
        <button
          style={{
            width: "100px",
            backgroundColor: "#9456",
          }}
          onClick={backHome}
        >
          <FaArrowLeft size={20} />
        </button>
      </div>
      <h2>Create a new account: </h2>
      <div id="m-container"></div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Phone Number:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            placeholder="Phone Number"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            placeholder="password"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder="email"
            onChange={handleChange}
          />
        </div>
        <div>
          <label>First Name:</label>
          <textarea
            name="name"
            value={formData.name}
            placeholder="First Name"
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div>
          <label>Last Name:</label>
          <textarea
            name="lastname"
            value={formData.lastname}
            placeholder="Last Name"
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div>
          <label>Role:</label>
          <input
            type="role"
            name="role"
            value={formData.role}
            placeholder="role"
            onChange={handleChange}
          />
        </div>
        {/* <div className="fileupload">
          <label>Upload Image: </label>
          <input
            type="file"
            name="image"
            id="file"
            onChange={handleFileUpload}
          />
          <button className="accessCamera">
            <FaArrowLeft />
          </button>
        </div> */}
        <button type="submit" className="submitButton">
          Save
        </button>
      </form>
      {selectedFile && (
        <div className="img-container">
          <img
            src={selectedFile}
            alt="img"
            style={{ width: "300px", height: "auto" }}
          />
          <button onClick={handleDownload}>Download</button>
        </div>
      )}
    </div>
  );
}

export default CreateAccount;
