import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { ServerApi, retrieveDataFromLocalStorage } from "../../utils/Constants";
import "./AllItems.css";

const ViewAllItems = () => {
  const localData = retrieveDataFromLocalStorage();
  const [Items, setItems] = useState([]);
  useEffect(() => {
    fetchItems();
  }, []);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }
  function fetchItems() {
    fetch(`${ServerApi}/admin/items`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localData.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setItems(data.result);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <main id="main" className="main">
      <div className="itemsContainer">
        <h2>Registered Items</h2>
        <p>all items in the system</p>
        <Table hover className="itemsTable">
          <thead>
            <tr className="tableHeadRow">
              <th>Index</th>
              <th>Owner ID</th>
              <th>Item Name</th>
              <th>Serial Number</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Date Of Registration</th>
              {/* <th>Registered At</th> */}
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody className="itemsTableBody">
            {Items.map((item, index) => (
              <tr key={index} className="itemDocument">
                <td> {index + 1}</td>
                <td> {item.ownerId}</td>
                <td> {item.brandName}</td>
                <td> {item.serialNumber}</td>
                <td> {item.description}</td>
                <td> {item.quantity}</td>
                <td> {formatDate(new Date(item.createdAt))}</td>
                {/* <td>
                  <img className="itemImage" src={item.image} alt="Image" />
                </td> */}
                {/* <td>
                  <td style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      onClick={() => handleEdit(item)}
                      style={{ marginRight: "5px" }}
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(item)}
                      style={{
                        backgroundColor: "red",
                      }}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </main>
  );
};

export default ViewAllItems;
