import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
// import Notify from "../../utils/Notify";
import {
  address,
  ServerApi,
  retrieveDataFromLocalStorage,
} from "../../../utils/Constants";
// import "./StylesFile.css";

const AddUser = ({ showModalRegister, setShowModalRegister }) => {
  // const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [validError, setValidError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({
    phone: "",
    password: "",
    email: "",
    name: "",
    lastname: "",
    role: "",
  });

  const localData = retrieveDataFromLocalStorage();
  // const showMessage = (messageContent, backgroundColor) => {
  //   setMessage({ content: messageContent, bgColor: backgroundColor });
  // };

  // const clearMessage = () => {
  //   setMessage(null);
  // };

  const registerAccount = (e) => {
    setValidError("");

    // Perform validation
    let isValid = true;
    if (!isValidEmail(user.email)) {
      alert("Please enter a valid email address.");
      isValid = false;
    }
    if (!isValidPhone(user.phone)) {
      alert("Please enter a valid phone number.");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    e.preventDefault();
    fetch(`${ServerApi}/admin/register`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localData.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.success) {
          setMessage("New Account Created successfully.");
          // setNotice(["New account created successfully", "success"]);
          setShowModalRegister(false);
        } else {
          // setNotice(["something went wrong", "failure"]);
          setMessage("Something went wrong!");
        }
      })
      .catch((err) => console.log(err));
  };
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^(\+251|0)(9|1)([0-9]){8}$/.test(phone);
  };

  return (
    <div>
      {/* <Notify notice={message} setNotice={setMessage} status={"info"} /> */}
      <Modal
        show={showModalRegister}
        onHide={() => setShowModalRegister(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter First Name"
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                placeholder="Enter Last Name"
                onChange={(e) => setUser({ ...user, lastname: e.target.value })}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Enter Phone Number"
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                // pattern="/^(\+251|0)(9|1)([0-9]){8}$/"
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter Email"
                autoComplete="off"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter Password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                className="customInput"
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={(e) => setUser({ ...user, role: e.target.value })}
                className="customInput"
              >
                {address.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={() => setShowModalRegister(false)}
            className="customCancelButton"
          >
            Cancel
          </Button> */}
          <Button
            variant="success"
            onClick={registerAccount}
            className="primary-btn"
          >
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Notify notice={notice[0]} setNotice={setNotice} status={notice[1]} /> */}
    </div>
  );
};

export default AddUser;
