import React from "react";
import "./Sidebar.css";
import { useNavigate, Link } from "react-router-dom";

const SidebarSubItem = ({ label, to }) => {
  const navigate = useNavigate();
  return (
    <li className="sidebar-subitem">
      <Link to={to}>{label}</Link>
    </li>
  );
};

export default SidebarSubItem;
