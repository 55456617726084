import { Outlet } from "react-router-dom";
// import Navbar from "./profile/Navbar";
// import Sidebar from "./superAdmin/Sidebar";
import MainPage from "../dashboard/MainPage";

const Layout = ({ SidebarList, NavList }) => {
  return (
    <>
      <MainPage SidebarList={SidebarList} NavList={NavList} />
      <main className="App">
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
