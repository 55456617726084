import React from "react";
import { Route, Routes } from "react-router-dom";

import Comment from "../modules/superAdmin/Comment";
import LinkPage from "../modules/profile/LinkPage";
// import Sidebar from "../modules/superAdmin/Sidebar";
// import CreateAccount from "../modules/superAdmin/CreateAccount";
import MainPage from "../dashboard/MainPage";
import NonPage from "../modules/NonPage";
import { agentSidebarList } from "../dashboard/data/SidebarItem";
import { agentNavList } from "../dashboard/data/NavItem";
import AllCereals from "../modules/agent/AllCereals";

const AgentRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <MainPage SidebarList={agentSidebarList} NavList={agentNavList} />
        }
      />
      <Route path="/comment" element={<Comment />} />
      <Route path="/new" element={<LinkPage />} />
      <Route path="/cereals" element={<AllCereals />} />
      <Route path="*" element={<NonPage />} />
    </Routes>
  );
};

export default AgentRoutes;
