import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import NavItem from "./NavItem";
import SidebarItem from "./SidebarItem";
import { handleLogout } from "../../utils/Constants";
// import { adminSidebarList } from "../data/SidebarItem";

function Sidebar({ SidebarList, NavList }) {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link to="/admin" className="nav-link">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {SidebarList.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
        {/* 
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-menu-button-wide"></i>
            <span>Documents</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="components-nav"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>Customers</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>Suppliers</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>logistics</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#icons-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-gem"></i>
            <span>Icons</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="icons-nav"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>react icons</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>bootstrap icons</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>font awesome icons</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#forms-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-journal-text"></i>
            <span>Formmmmmms</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>

          <ul
            id="forms-nav"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>Application form</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>registration form</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>form handle</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#tables-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-layout-text-window-reverse"></i>
            <span>table</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="tables-nav"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>general tables</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>form tables</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>other form tables</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#charts-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-bar-chart"></i>
            <span>charts</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>

          <ul
            id="charts-nav"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>generatl trending chart</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>data charys handler</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-circle"></i>
                <span>optins get here</span>
              </a>
            </li>
          </ul>
        </li> */}
        <li className="nav-heading">Pages</li>
        {NavList.map((nav) => (
          <NavItem key={nav._id} nav={nav} />
        ))}
        {/* <li className="nav-item">
          <a className="nav-link collapsed" onClick={handleLogout}>
            <i className="bi bi-door-closed-fill"></i>
            <span>Logout</span>
          </a>
        </li> */}
      </ul>
    </aside>
  );
}

export default Sidebar;
