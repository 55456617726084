import React from "react";
import { FaCameraRetro } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { SlNote } from "react-icons/sl";

import {
  FaChartLine,
  FaComments,
  FaShoppingCart,
  FaBullhorn,
} from "react-icons/fa";

const skillsData = [
  {
    name: "Market information sharing",
    icon: <FaChartLine className="text-4xl text-primary" />,
    link: "#",
    description:
      "The company creates a centralized platform where businesses can connect and share market information. This includes: Industry trends and reports, Supplier and buyer directories, Product listings and pricings, Discussion forums for businesses to connect and share knowledge",
    aosDelay: "0",
  },
  {
    name: "Digital Marketing Forum Hosting",
    icon: <FaComments className="text-4xl text-primary" />,
    link: "#",
    description:
      "The company specializes in managing and hosting digital marketing forums, providing a dedicated platform for professionals to engage, share insights and analyse industry trends. participants can connect with peers, and discuss best practices in digital marketing.",
    aosDelay: "300",
  },
  {
    name: "Online Ordering System",
    icon: <FaShoppingCart className="text-4xl text-primary" />,
    link: "#",
    description:
      "We Offer an online ordering system where businesses can browse products, place orders, and manage transactions conveniently through our centralized platform.",
    aosDelay: "500",
  },
  {
    name: "Product Promotion Platform",
    icon: <FaBullhorn className="text-4xl text-primary" />,
    link: "#",
    description:
      "Our platform provides businesses with a powerful tool to showcase and promote their products to a wide audience. With easy-to-use features and comprehensive support, clients can effectively share their offerings, attract potential customers, and drive sales.",
    aosDelay: "700",
  },
];
const Services = () => {
  return (
    <>
      <span id="about"></span>
      <div className="bg-gray-100 dark:bg-black dark:text-white py-12 sm:grid sm:place-items-center">
        <div className="container">
          {/* Header */}
          <div className="pb-12 text-center space-y-3">
            <h1
              data-aos="fade-up"
              className="text-3xl font-semibold sm:text-3xl text-violet-950 dark:text-primary"
            >
              Explore Our Services
            </h1>
            <p
              data-aos="fade-up"
              className="text-gray-600 dark:text-gray-400 text-sm"
            >
              we are here to serve your market networking demands in innovative
              ways, and make your delivary within your fingertips
            </p>
          </div>

          {/* services cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            {skillsData.map((skill) => (
              <div
                key={skill.name}
                data-aos="fade-up"
                data-aos-delay={skill.aosDelay}
                className="card space-y-3 sm:space-y-4 p-4"
              >
                <div>{skill.icon}</div>
                <h1 className="text-lg font-semibold">{skill.name}</h1>
                <p className="text-gray-600 dark:text-gray-400">
                  {skill.description}
                </p>
              </div>
            ))}
          </div>

          {/* button */}
          <div
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-offset="0"
            className="text-center mt-4 sm:mt-8"
          >
            <button className="primary-btn">Learn More</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
