import React from "react";
import "./Sidebar.css";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const sidebarItems = [
    {
      label: "Dashboard",
      subItems: [
        { label: "Overview", to: "/admin/createAccount" },
        { label: "Statistics", to: `Comment` },
      ],
    },
    {
      label: "Settings",
      subItems: [
        { label: "active", to: "/home" },
        { label: "logout", to: "/admin/dashboard" },
      ],
    },
  ];

  return (
    <div className="Sidebar">
      <div className="sidebar">
        {sidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            label={item.label}
            subItems={item.subItems}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
