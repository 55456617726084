import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function retrieveDataFromLocalStorage() {
  const jsonData = localStorage.getItem("AuthData");
  if (jsonData) {
    const myObject = JSON.parse(jsonData);

    return myObject;
  }
}

const RequireAuth = ({ allowedRoles }) => {
  // const { auth } = useAuth();
  // const location = useLocation();
  const myObject = retrieveDataFromLocalStorage();
  if (!myObject) {
    <Navigate to="/login" />;
  } else {
    return allowedRoles?.includes(myObject.role) ? (
      <Outlet />
    ) : myObject.user ? (
      <Navigate to="/unauthorized" />
    ) : (
      <Navigate to="/login" />
    );
  }
};

export default RequireAuth;
