import React from "react";
import { Link } from "react-router-dom";

function SidebarItem({ item }) {
  return (
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        data-bs-target={`#${item.target}`}
        data-bs-toggle="collapse"
        href={item.to}
      >
        <i className={item.icon}></i>
        <span>{item.name}</span>
        <i className="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id={item.target}
        className="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        {item.subItems.map((subItem, index) => (
          <li key={index}>
            <Link to={subItem.link}>
              <i className="bi bi-circle"></i>
              <span>{subItem.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

export default SidebarItem;
