import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServerApi, retrieveDataFromLocalStorage } from "../../utils/Constants";
import { FaEdit, FaTrash } from "react-icons/fa";
import "./AllItems.css";

// import UserList from "./EdittingModal";

const AllCereals = () => {
  var counter = 1;
  const localData = retrieveDataFromLocalStorage();
  const [user, setUser] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  useEffect(() => {
    fetchCereals();
  }, []);

  const handleRemoveUser = (id) => {
    const filteredItems = user.filter((item) => item.phone !== id);
    setUser(filteredItems);
  };

  function fetchCereals() {
    fetch(`${ServerApi}/agent/cereals`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localData.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setUser(data.result);
        }
      })
      .catch((err) => console.log(err));
  }
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      fetch(`${ServerApi}/admin/deleteUser/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localData.token}`,
        },
      }).then((result) => {
        result.json().then((data) => {
          if (data.success) {
            handleRemoveUser(id);
          } else {
            alert(data.message);
          }
        });
      });
    }
  };
  return (
    <main id="main" className="main">
      <div className="px-5 mt-3">
        <div className="d-flex flex-column justify-content-left">
          <h2>Cereals</h2>
          <p>list of all cereals</p>
        </div>

        <Link to="/admin/createAccount" className="btn btn-success">
          create account
        </Link>

        <div className="mt-3">
          <table className="table">
            <thead>
              <tr className="tableHeadRow">
                <th>No.</th>
                <th>Cereal Name</th>
                <th>Colour</th>
                <th>Type</th>

                <th>Description</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {user.map((e) => (
                <tr>
                  <td>{counter++}</td>
                  <td>{e.name}</td>
                  {/* <td>
                  <img
                    src={`http://localhost:3000/Images/` + e.image}
                    className="user_image"
                  />
                </td> */}
                  {/* <td>{e.lastname}</td>
                  <td>{e.phone}</td>
                  <td>{e.email}</td>

                  <td>{e.role}</td> */}
                  <td>
                    <FaEdit
                      className="iconsAction"
                      style={{
                        color: "rgb(49, 215, 245)",
                      }}
                      onClick={() => {
                        setShowModal(true);
                        setSelectedUser(e);
                      }}
                    />

                    <FaTrash
                      className="iconsAction"
                      style={{
                        marginLeft: "10px",
                        color: "red",
                      }}
                      onClick={() => handleDelete(e.phone)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* <UserList
            showModal={showModal}
            setShowModal={setShowModal}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          /> */}
        </div>
      </div>
    </main>
  );
};

export default AllCereals;
