import React, { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import SidebarSubItem from "./SidebarSubItem";

const SidebarItem = ({ label, subItems }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="sidebar-item" onClick={toggleExpansion}>
      <div className="item-label">
        {expanded ? <FaAngleDown /> : <FaAngleRight />}
        <span>{label}</span>
      </div>
      {expanded && (
        <ul className="sub-items">
          {subItems.map((subItem, index) => (
            <SidebarSubItem key={index} label={subItem.label} to={subItem.to} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SidebarItem;
