import React, { useRef, useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
// import "./Styles.css";
import { MenuLinksMobile } from "./Navbar";
import Register from "../Register";

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  console.log("showMenu", showMenu);
  const sidebarRef = useRef(null);
  const [showModalRegister, setShowModalRegister] = useState(false);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div
      ref={sidebarRef}
      className={`${
        showMenu ? "left-0" : "-left-[100%]"
      } fixed bottom-0 top-0 z-20 flex h-screen w-[75%] flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-16 text-black transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
    >
      <div className="card">
        {/* <div className="flex items-center justify-start gap-3">
          <FaUserCircle size={50} />
          <div>
            <h1>Hello User</h1>
            <h1 className="text-sm text-slate-500">Premium user</h1>
          </div>
        </div> */}
        <nav className="mt-12">
          <ul className="space-y-1 text-xl">
            {MenuLinksMobile.map((data) => (
              <li key={data.name}>
                <a
                  onClick={() => {
                    setShowMenu(false);
                    if (data.name === "subscribe") {
                      setShowModalRegister(true);
                    }
                  }}
                  href={data.link}
                  className="mb-5 inline-block hover:bg-blue-100 hover:text-primary hover:border-b-2 hover:border-primary transition-all duration-300"
                >
                  {data.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <Register
        showModalRegister={showModalRegister}
        setShowModalRegister={setShowModalRegister}
      />
      {/* <div className="footer">
        <h1>
          Made with ❤ by <a href="https://dilshad-ahmed.github.io/">Dilshad</a>{" "}
        </h1>
      </div> */}
    </div>
  );
};

export default ResponsiveMenu;
