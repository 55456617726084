export const adminNavList = [
  {
    _id: 1,
    name: "Profile",
    icon: "bi bi-person",
    to: "/admin/profile",
  },

  {
    _id: 2,
    name: "contact",
    icon: "bi bi-envelope",
    to: "/admin/contact",
  },
  {
    _id: 3,
    name: "setting",
    icon: "bi bi-gear",
    to: "/admin/setting",
  },

  {
    _id: 7,
    name: "logout",
    icon: "bi bi-box-arrow-in-right",
    to: "/admin/logout",
  },
];

export const traderNavList = [
  {
    _id: 1,
    name: "Profile",
    icon: "bi bi-person",
    to: "/trader/profile",
  },

  {
    _id: 2,
    name: "contact",
    icon: "bi bi-envelope",
    to: "/trader/contact",
  },
  {
    _id: 3,
    name: "setting",
    icon: "bi bi-gear",
    to: "/trader/setting",
  },

  {
    _id: 6,
    name: "comment",
    icon: "bi bi-file-earmark",
    to: "/comment",
  },
  {
    _id: 7,
    name: "logout",
    icon: "bi bi-box-arrow-in-right",
    to: "/trader/logout",
  },
];

export const agentNavList = [
  {
    _id: 1,
    name: "Profile",
    icon: "bi bi-person",
    to: "/agent/profile",
  },

  {
    _id: 2,
    name: "contact",
    icon: "bi bi-envelope",
    to: "/agent/contact",
  },
  {
    _id: 3,
    name: "setting",
    icon: "bi bi-gear",
    to: "/agent/setting",
  },
  {
    _id: 4,
    name: "register",
    icon: "bi bi-card-list",
    to: "/agent/register",
  },
  {
    _id: 5,
    name: "FAQ",
    icon: "bi bi-question-circle",
    to: "/profile",
  },
  {
    _id: 6,
    name: "comment",
    icon: "bi bi-file-earmark",
    to: "/comment",
  },
  {
    _id: 7,
    name: "logout",
    icon: "bi bi-box-arrow-in-right",
    to: "/logout",
  },
];
