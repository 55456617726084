import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./MainPage.css";
import Header from "./comp/Header";
import Sidebar from "./comp/Sidebar";
import Main from "./comp/Main";

function MainPage({ SidebarList, NavList }) {
  return (
    <>
      <Header />;
      <Sidebar SidebarList={SidebarList} NavList={NavList} />;
      <Main />
    </>
  );
}

export default MainPage;
