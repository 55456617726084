import React from "react";
import "./Logo.css";

function Logo() {
  const handleToggleSidebar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <i
        className="bi bi-list toggle-sidebar-btn"
        onClick={handleToggleSidebar}
      ></i>{" "}
      <a href="/" className="logo d-flex align-items-center">
        {/*<img src="" alt=""/>*/}
        <span className="d-none d-lg-block">ecomspot</span>
      </a>
    </div>
  );
}

export default Logo;
